/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApplicationConfig, inject } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Body } from 'apollo-angular/http/types';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import { toLocaleDate, toYearMonthDate } from './utils/date';
import { environment } from '../environments/environment';

const INSTANT_DATE_OPERATIONS = ['GetDashboard', 'CreateTask', 'UpdateTask', 'CreateRequest', 'UpdateRequest'];

const DOCUMENT_OPERATIONS = ['UploadDocument'];

const YEAR_MONTH_OPERATIONS = ['UpdateInternationalApplication'];

const NOT_LOCALE_DATE_OPERATIONS = [...INSTANT_DATE_OPERATIONS, ...DOCUMENT_OPERATIONS, ...YEAR_MONTH_OPERATIONS];

function transformDatesToLocaleDate(obj: any): any {
  if (obj === null || obj === undefined) return obj;

  if (obj instanceof Date) {
    // Convert Date to local date string format
    return toLocaleDate(obj);
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => transformDatesToLocaleDate(item));
  }

  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = transformDatesToLocaleDate(obj[key]);
      return acc;
    }, {} as any);
  }

  return obj;
}

function transformDatesToYearMonth(obj: any): any {
  if (obj === null || obj === undefined) return obj;

  if (obj instanceof Date) {
    // Convert Date to local date string format
    return toYearMonthDate(obj);
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => transformDatesToYearMonth(item));
  }

  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = transformDatesToYearMonth(obj[key]);
      return acc;
    }, {} as any);
  }

  return obj;
}

export function apolloOptionsFactory(): ApolloClientOptions<unknown> {
  const httpLink = inject(HttpLink);

  const dateMiddleware = new ApolloLink((operation, forward) => {
    if (!NOT_LOCALE_DATE_OPERATIONS.includes(operation.operationName)) {
      operation.variables = transformDatesToLocaleDate(operation.variables);
    }

    if (YEAR_MONTH_OPERATIONS.includes(operation.operationName)) {
      operation.variables = transformDatesToYearMonth(operation.variables);
    }

    return forward(operation);
  });

  return {
    link: ApolloLink.from([
      dateMiddleware,
      httpLink.create({
        uri: environment.apiGraphQlPath,
        extractFiles: (body) =>
          extractFiles(body, isExtractableFile) as {
            clone: Body;
            files: Map<any, any>;
          },
      }),
    ]),
    cache: new InMemoryCache(),
  };
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
  },
];
