import { registerLocaleData } from '@angular/common';
import { HttpClientModule, provideHttpClient } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxEditorModule } from 'ngx-editor';

import { routes } from './app.routes';
import { graphqlProvider } from './graphql.provider';
import { DynamicImportLoader } from './i18n/dynamic-import-loader';
import { accountCircleIcon } from './svg/account-circle';
import { accountPinIcon } from './svg/account-pin';
import { addLineIcon } from './svg/add-line';
import { applicationIcon } from './svg/application';
import { archiveIcon } from './svg/archive';
import { arrowIcon } from './svg/arrow';
import { arrowLeftIcon } from './svg/arrow-left';
import { arrowUpIcon } from './svg/arrow-up';
import { cakeLineIcon } from './svg/cake-line';
import { calendarEventIcon } from './svg/calendar-event';
import { callIcon } from './svg/call';
import { chatSmileIcon } from './svg/chat-smile';
import { checkIcon } from './svg/check';
import { checkOutlineIcon } from './svg/check-outline';
import { chevronDownIcon } from './svg/chevron-down';
import { circleIcon } from './svg/circle';
import { closeIcon } from './svg/close';
import { closeCircleIcon } from './svg/close-circle';
import { communicationIcon } from './svg/communication';
import { copyIcon } from './svg/copy';
import { dashboardLineIcon } from './svg/dashboard-line';
import { deleteBackIcon } from './svg/delete-back';
import { deleteBinLineIcon } from './svg/delete-bin-line';
import { downloadIcon } from './svg/download';
import { dragHandleIcon } from './svg/drag-handle';
import { editIcon } from './svg/edit';
import { emailIcon } from './svg/email';
import { expandIcon } from './svg/expand';
import { eyeLineIcon } from './svg/eye-line';
import { fileIcon } from './svg/file';
import { filterIcon } from './svg/filter';
import { flagIcon } from './svg/flag';
import { freshManIcon } from './svg/fresh-man';
import { groupLineIcon } from './svg/group-line';
import { homeLineIcon } from './svg/home-line';
import { inboxUnarchiveIcon } from './svg/inbox-unarchive';
import { infoIcon } from './svg/info';
import { interculturaIcon } from './svg/intercultura';
import { leadIcon } from './svg/lead';
import { linksFillIcon } from './svg/links-fill';
import { loadingIcon } from './svg/loading';
import { logOutIcon } from './svg/log-out';
import { mailLineIcon } from './svg/mail-line';
import { mailOpenIcon } from './svg/mail-open';
import { mailUnreadIcon } from './svg/mail-unread';
import { mapPinLineIcon } from './svg/map-pin-line';
import { mapPinRangeLineIcon } from './svg/map-pin-range-line';
import { moreFillIcon } from './svg/more-fill';
import { moreFillOrizzontalIcon } from './svg/more-fill-orizzontal';
import { noteLineIcon } from './svg/note-line';
import { notificationIcon } from './svg/notification';
import { parentIcon } from './svg/parent';
import { passportLineIcon } from './svg/passport-line';
import { personIcon } from './svg/person';
import { phoneLineIcon } from './svg/phone-line';
import { pinDropIcon } from './svg/pin-drop';
import { playCircleIcon } from './svg/play-circle';
import { playMiniIcon } from './svg/play-mini';
import { priceTagIcon } from './svg/price-tag';
import { programIcon } from './svg/program';
import { progressIcon } from './svg/progress';
import { schoolLineIcon } from './svg/school-line';
import { searchIcon } from './svg/search';
import { searchEyeIcon } from './svg/search-eye';
import { settingIcon } from './svg/setting';
import { sortAlphabetIcon } from './svg/sort-alphabet';
import { stackLineIcon } from './svg/stack-line';
import { starIcon } from './svg/star';
import { starSmileIcon } from './svg/star-smile';
import { stickyNoteIcon } from './svg/sticky-note';
import { stopCircleIcon } from './svg/stop-circle';
import { studentIcon } from './svg/student';
import { subtractLineIcon } from './svg/subtract-line';
import { sunIcon } from './svg/sun';
import { taskIcon } from './svg/task';
import { textIcon } from './svg/text';
import { textFieldIcon } from './svg/text-field';
import { unknownIcon } from './svg/unknown';
import { uploadIcon } from './svg/upload';
import { userAddLineIcon } from './svg/user-add-line';
import { whatsappIcon } from './svg/whatsapp';
import { environment } from '../environments/environment';

export const svgIcons = [
  accountCircleIcon,
  accountPinIcon,
  accountPinIcon,
  addLineIcon,
  applicationIcon,
  archiveIcon,
  arrowIcon,
  arrowLeftIcon,
  arrowUpIcon,
  cakeLineIcon,
  calendarEventIcon,
  callIcon,
  chatSmileIcon,
  checkIcon,
  checkOutlineIcon,
  checkOutlineIcon,
  chevronDownIcon,
  circleIcon,
  circleIcon,
  closeCircleIcon,
  closeIcon,
  closeIcon,
  communicationIcon,
  communicationIcon,
  copyIcon,
  copyIcon,
  dashboardLineIcon,
  dashboardLineIcon,
  deleteBackIcon,
  deleteBinLineIcon,
  deleteBinLineIcon,
  downloadIcon,
  dragHandleIcon,
  editIcon,
  emailIcon,
  expandIcon,
  eyeLineIcon,
  fileIcon,
  filterIcon,
  flagIcon,
  flagIcon,
  freshManIcon,
  groupLineIcon,
  homeLineIcon,
  inboxUnarchiveIcon,
  infoIcon,
  interculturaIcon,
  leadIcon,
  linksFillIcon,
  loadingIcon,
  logOutIcon,
  mailLineIcon,
  mailOpenIcon,
  mailUnreadIcon,
  mapPinLineIcon,
  mapPinRangeLineIcon,
  moreFillIcon,
  moreFillIcon,
  moreFillOrizzontalIcon,
  noteLineIcon,
  notificationIcon,
  parentIcon,
  passportLineIcon,
  personIcon,
  phoneLineIcon,
  pinDropIcon,
  playCircleIcon,
  playMiniIcon,
  priceTagIcon,
  programIcon,
  progressIcon,
  schoolLineIcon,
  searchEyeIcon,
  searchIcon,
  settingIcon,
  sortAlphabetIcon,
  stackLineIcon,
  starIcon,
  starSmileIcon,
  stickyNoteIcon,
  stopCircleIcon,
  studentIcon,
  subtractLineIcon,
  sunIcon,
  taskIcon,
  textFieldIcon,
  textIcon,
  unknownIcon,
  uploadIcon,
  userAddLineIcon,
  whatsappIcon,
];

export type SvgName = (typeof svgIcons)[number]['name'];

export interface RouteLink {
  routerLink?: string;
  routerParams?: { [key: string]: string | number };
  label: string;
  icon?: SvgName;
  disable?: boolean;
}

registerLocaleData(localeIt);

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
    provideRouter(routes, withComponentInputBinding()),
    provideSvgIconsConfig({
      icons: svgIcons,
      missingIconFallback: unknownIcon,
    }),
    importProvidersFrom(
      BrowserAnimationsModule,
      HttpClientModule,
      NgxEditorModule.forRoot({
        locals: {
          bold: 'Bold',
          italic: 'Italic',
          blockquote: 'Blockquote',
          underline: 'Underline',
          strike: 'Strike',
          text_color: 'Text Color',
          bullet_list: 'Bullet List',
          ordered_list: 'Ordered List',
          align_left: 'Left Align',
          align_center: 'Center Align',
          align_right: 'Right Align',
          align_justify: 'Justify',
          background_color: 'Background Color',
        },
      }),
      TranslateModule.forRoot({
        defaultLanguage: environment.defaultLang,
        loader: {
          provide: TranslateLoader,
          useClass: DynamicImportLoader,
        },
      }),
      GoogleChartsModule.forRoot({ version: 'current' }),
    ),
    provideHttpClient(),
    graphqlProvider,
  ],
};
